import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

// Middleware component to handle redirection logic
const OrgRedirectMiddleware = () => {
  const navigate = useNavigate();
  const { uniqueId } = useParams();

  useEffect(() => {
    const handleRedirect = async () => {
      if (uniqueId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API}/job/unique-id/${uniqueId}`
          );
          const data = await response.json();

          if (response.status === 404 || response.status === 400) {
            toast.error(data.message || "Job not found");
            navigate("/404");
          } else if (data.data && data.data.url) {
            window.location.href = data.data.url;
          } else {
            navigate("/404");
          }
        } catch (error) {
          toast.error("An unexpected error occurred.");
          navigate("/404");
        }
      } else {
        navigate("/");
      }
    };

    handleRedirect();
  }, [navigate, uniqueId]);

  return null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:uniqueId" element={<OrgRedirectMiddleware />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />{" "}
        {/* Catch-all route for 404 */}
      </Routes>
    </Router>
  );
};

export default App;
