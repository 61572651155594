import React, { useState } from "react";
import Modal from "./Modal";

const Feature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  return (
    <>
      <section id="features" className="py-20 bg-gray-50 relative">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-5xl font-bold text-indigo-600 leading-tight">
            Key Features
          </h2>
          <p className="text-xl mt-4 text-gray-700 max-w-3xl mx-auto leading-relaxed">
            Revolutionize hiring with AI-powered tools, delivering better
            candidates faster.
          </p>
          {/* <!-- Feature Cards --> */}
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* <!-- Feature 1 --> */}
            <div className="feature-card relative bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:-translate-y-2 hover:scale-105">
              <div className="absolute top-0 left-0 w-full h-1 rounded-t-lg bg-gradient-to-r from-indigo-600 to-purple-600"></div>
              <div className="flex justify-center items-center w-16 h-16 mx-auto bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full">
                <i className="fas fa-brain text-3xl"></i>
              </div>
              <h3 className="mt-6 text-2xl font-semibold text-indigo-600">
                AI Precision Screening
              </h3>
              <p className="mt-4 text-gray-600">
                Identify the top 5% of candidates instantly, saving time while
                ensuring top quality.
              </p>
              <div className="mt-6">
                <a
                  onClick={toggleModal}
                  className="text-indigo-600 font-semibold hover:text-purple-600 transition duration-300 cursor-pointer"
                >
                  Learn More &rarr;
                </a>
              </div>
            </div>
            {/* <!-- Feature 2 --> */}
            <div className="feature-card relative bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:-translate-y-2 hover:scale-105">
              <div className="absolute top-0 left-0 w-full h-1 rounded-t-lg bg-gradient-to-r from-indigo-600 to-purple-600"></div>
              <div className="flex justify-center items-center w-16 h-16 mx-auto bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full">
                <i className="fas fa-user-friends text-3xl"></i>
              </div>
              <h3 className="mt-6 text-2xl font-semibold text-indigo-600">
                Cultural Fit Alignment
              </h3>
              <p className="mt-4 text-gray-600">
                Assess how candidates match your culture, ensuring a long-term
                fit.
              </p>
              <div className="mt-6">
                <a
                  onClick={toggleModal}
                  className="text-indigo-600 font-semibold hover:text-purple-600 transition duration-300 cursor-pointer"
                >
                  Explore How &rarr;
                </a>
              </div>
            </div>
            {/* <!-- Feature 3 --> */}
            <div className="feature-card relative bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:-translate-y-2 hover:scale-105">
              <div className="absolute top-0 left-0 w-full h-1 rounded-t-lg bg-gradient-to-r from-indigo-600 to-purple-600"></div>
              <div className="flex justify-center items-center w-16 h-16 mx-auto bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full">
                <i className="fas fa-chart-pie text-3xl"></i>
              </div>
              <h3 className="mt-6 text-2xl font-semibold text-indigo-600">
                Real-Time Analytics
              </h3>
              <p className="mt-4 text-gray-600">
                Track key hiring metrics live and optimize your strategies on
                the go.
              </p>
              <div className="mt-6">
                <a
                  onClick={toggleModal}
                  className="text-indigo-600 font-semibold hover:text-purple-600 transition duration-300 cursor-pointer"
                >
                  Discover More &rarr;
                </a>
              </div>
            </div>
          </div>
          {/* <!-- Subtle Background Design Elements (optional) --> */}
          <div className="absolute top-0 right-0 w-64 h-64 bg-indigo-200 rounded-full opacity-20 blur-3xl -z-10"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-200 rounded-full opacity-20 blur-3xl -z-10"></div>
        </div>
      </section>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
};

export default Feature;
