import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 TalentSwap.ai. All rights reserved.</p>
        <div className="mt-4 space-x-6">
        </div>
      </div>
    </footer>
  );
};

export default Footer;
