import React, { useState } from "react";
import Modal from "./Modal";

const FeatureComparison = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  return (
    <>
      <section id="features-comparison" className="py-20 bg-gray-50 relative">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-5xl font-extrabold text-indigo-700">
            Why TalentSwap Stands Out
          </h2>
          <p className="text-lg mt-4 text-gray-600 max-w-3xl mx-auto">
            Experience the AI-powered features that make TalentSwap the best
            choice for your recruitment needs.
          </p>
          {/* <!-- Key Features --> */}
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
            {/* <!-- Feature 1 --> */}
            <div className="feature-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105">
              <div className="icon flex items-center justify-center bg-indigo-100 rounded-full w-16 h-16 mx-auto">
                <i className="fas fa-brain text-indigo-600 text-3xl"></i>
              </div>
              <h3 className="mt-6 text-xl font-semibold text-indigo-700">
                AI-Driven Efficiency
              </h3>
              <p className="mt-4 text-gray-700">
                Leverage AI to automate repetitive tasks, drastically cutting
                down manual work, and boosting overall hiring speed.
              </p>
            </div>
            {/* <!-- Feature 2 --> */}
            <div className="feature-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105">
              <div className="icon flex items-center justify-center bg-indigo-100 rounded-full w-16 h-16 mx-auto">
                <i className="fas fa-user-friends text-indigo-600 text-3xl"></i>
              </div>
              <h3 className="mt-6 text-xl font-semibold text-indigo-700">
                Deeper Cultural Alignment
              </h3>
              <p className="mt-4 text-gray-700">
                Our assessments go beyond technical skills to evaluate values,
                helping you build a team that truly fits your culture.
              </p>
            </div>
            {/* <!-- Feature 3 --> */}
            <div className="feature-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105">
              <div className="icon flex items-center justify-center bg-indigo-100 rounded-full w-16 h-16 mx-auto">
                <i className="fas fa-tools text-indigo-600 text-3xl"></i>
              </div>
              <h3 className="mt-6 text-xl font-semibold text-indigo-700">
                Seamless Integrations
              </h3>
              <p className="mt-4 text-gray-700">
                Easily integrate TalentSwap with your existing HR tools,
                including Applicant Tracking Systems (ATS) and CRM platforms.
              </p>
            </div>
          </div>
          {/* <!-- Competitor Comparison --> */}
          <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="comparison-table bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-semibold text-indigo-700 mb-4">
                TalentSwap vs Competitors
              </h3>
              <table className="table-auto w-full mt-4 text-left border-collapse">
                <thead className="hidden md:table-header-group">
                  <tr>
                    <th className="px-4 py-2 text-indigo-600">Feature</th>
                    <th className="px-4 py-2 text-indigo-600">TalentSwap</th>
                    <th className="px-4 py-2 text-gray-600">Others</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {/* AI Screening Accuracy */}
                  <tr className="bg-indigo-100 md:hidden">
                    <td className="px-4 py-2 font-semibold text-indigo-700 rounded-lg">
                      AI Screening Accuracy
                    </td>
                  </tr>
                  <tr className="flex md:hidden">
                    <td className="px-4 py-2 text-indigo-600 w-1/2">
                      <span className="block font-semibold">TalentSwap</span>
                      <span className="text-gray-600">Top 5%</span>
                    </td>
                    <td className="px-4 py-2 text-gray-600 w-1/2">
                      <span className="block font-semibold">Others</span>
                      <span>General Filtering</span>
                    </td>
                  </tr>

                  {/* Cultural Fit Assessment */}
                  <tr className="bg-indigo-100 md:hidden">
                    <td className="px-4 py-2 font-semibold text-indigo-700 rounded-lg">
                      Cultural Fit Assessment
                    </td>
                  </tr>
                  <tr className="flex md:hidden">
                    <td className="px-4 py-2 text-indigo-600 w-1/2">
                      <span className="block font-semibold">TalentSwap</span>
                      <span className="text-gray-600">Included</span>
                    </td>
                    <td className="px-4 py-2 text-gray-600 w-1/2">
                      <span className="block font-semibold">Others</span>
                      <span>Not Offered</span>
                    </td>
                  </tr>

                  {/* Real-Time Analytics */}
                  <tr className="bg-indigo-100 md:hidden">
                    <td className="px-4 py-2 font-semibold text-indigo-700 rounded-lg">
                      Real-Time Analytics
                    </td>
                  </tr>
                  <tr className="flex md:hidden">
                    <td className="px-4 py-2 text-indigo-600 w-1/2">
                      <span className="block font-semibold">TalentSwap</span>
                      <span className="text-gray-600">Included</span>
                    </td>
                    <td className="px-4 py-2 text-gray-600 w-1/2">
                      <span className="block font-semibold">Others</span>
                      <span>Limited</span>
                    </td>
                  </tr>

                  {/* Customizable Reports */}
                  <tr className="bg-indigo-100 md:hidden">
                    <td className="px-4 py-2 font-semibold text-indigo-700 rounded-lg">
                      Customizable Reports
                    </td>
                  </tr>
                  <tr className="flex md:hidden">
                    <td className="px-4 py-2 text-indigo-600 w-1/2">
                      <span className="block font-semibold">TalentSwap</span>
                      <span className="text-gray-600">Available</span>
                    </td>
                    <td className="px-4 py-2 text-gray-600 w-1/2">
                      <span className="block font-semibold">Others</span>
                      <span>Not Available</span>
                    </td>
                  </tr>

                  {/* Desktop View */}
                  <tr className="border-t hidden md:table-row">
                    <td className="px-4 py-2">AI Screening Accuracy</td>
                    <td className="px-4 py-2">Top 5%</td>
                    <td className="px-4 py-2">General Filtering</td>
                  </tr>
                  <tr className="border-t hidden md:table-row">
                    <td className="px-4 py-2">Cultural Fit Assessment</td>
                    <td className="px-4 py-2">Included</td>
                    <td className="px-4 py-2">Not Offered</td>
                  </tr>
                  <tr className="border-t hidden md:table-row">
                    <td className="px-4 py-2">Real-Time Analytics</td>
                    <td className="px-4 py-2">Included</td>
                    <td className="px-4 py-2">Limited</td>
                  </tr>
                  <tr className="border-t hidden md:table-row">
                    <td className="px-4 py-2">Customizable Reports</td>
                    <td className="px-4 py-2">Available</td>
                    <td className="px-4 py-2">Not Available</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-indigo-100 p-6 rounded-xl shadow-lg text-center flex flex-col justify-center">
              <h3 className="text-2xl font-semibold text-indigo-700">
                Why TalentSwap is the Smart Choice
              </h3>
              <p className="mt-4 text-gray-700">
                Cut your hiring time, find better matches, and make data-driven
                decisions. TalentSwap brings AI precision and human insights to
                your recruitment process.
              </p>
              <a
                onClick={toggleModal}
                className="mt-6 bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition duration-300 w-full inline-block cursor-pointer"
              >
                Try TalentSwap Now
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
};

export default FeatureComparison;
