import React, { useState, useEffect } from "react";
import company1 from "../assets/companies/1.png";
import company2 from "../assets/companies/2.png";
import company3 from "../assets/companies/3.png";
import company4 from "../assets/companies/4.png";
import company5 from "../assets/companies/5.png";
import company6 from "../assets/companies/6.png";

const TrustedCompanies = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const companies = [
    { src: company1, alt: "Company 1" },
    { src: company2, alt: "Company 2" },
    { src: company3, alt: "Company 3" },
    { src: company4, alt: "Company 4" },
    { src: company5, alt: "Company 5" },
    { src: company6, alt: "Company 6" }
  ];

  return (
    <section className="py-8 sm:py-12 md:py-20 bg-gray-50 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-indigo-600 text-center">
          Trusted by Leading Companies
        </h2>
        <p className="text-base sm:text-lg md:text-xl mt-2 sm:mt-3 md:mt-4 text-gray-700 max-w-3xl mx-auto text-center leading-relaxed px-4">
          Join the innovators who trust TalentSwap.ai to transform their hiring
          process.
        </p>

        {/* Mobile View */}
        <div className="md:hidden mt-8 grid grid-cols-2 gap-6 px-4">
          {companies.map((company, index) => (
            <div
              key={`mobile-${index}`}
              className="flex items-center justify-center p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <img
                src={company.src}
                alt={company.alt}
                className="h-8 sm:h-10 w-auto object-contain"
              />
            </div>
          ))}
        </div>

        {/* Desktop/Tablet View */}
        <div className="hidden md:block relative mt-12">
          {/* Gradient Overlays */}
          <div className="absolute left-0 top-0 w-32 lg:w-40 h-full bg-gradient-to-r from-gray-50 to-transparent z-10" />
          <div className="absolute right-0 top-0 w-32 lg:w-40 h-full bg-gradient-to-l from-gray-50 to-transparent z-10" />

          {/* Carousel Container */}
          <div className="overflow-hidden whitespace-nowrap">
            {/* Track */}
            <div className="inline-flex animate-marquee">
              {/* First Set */}
              {companies.map((company, index) => (
                <div
                  key={`slide1-${index}`}
                  className="flex-shrink-0 px-2 lg:px-4"
                >
                  <div className="p-4 transition-shadow duration-300">
                    <img
                      src={company.src}
                      alt={company.alt}
                      className="w-48 object-contain"
                    />
                  </div>
                </div>
              ))}
              
              {/* Second Set */}
              {companies.map((company, index) => (
                <div
                  key={`slide2-${index}`}
                  className="flex-shrink-0 px-2 lg:px-4"
                >
                  <div className="p-4 transition-shadow duration-300">
                    <img
                      src={company.src}
                      alt={company.alt}
                      className="w-48 object-contain"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedCompanies;