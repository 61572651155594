import React, { useState, useEffect, useCallback } from "react";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleState = (setState) => () => setState((prev) => !prev);

  const handleScroll = useCallback(() => {
    setIsScrolled(window.pageYOffset > 50);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <header
      id="header"
      className={`fixed top-0 w-full z-50 transition-all duration-300 ease-in-out ${
        isScrolled ? "bg-scrolled bg-glass" : "bg-transparent"
      }`}
    >
      <nav className="container mx-auto flex justify-between items-center h-20 px-6">
        <a
          href="/"
          className="text-3xl font-extrabold hover:text-gray-200 transition duration-200 cursor-pointer"
        >
          <span className="text-white">TalentSwap.ai</span>
        </a>

        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-8 items-center text-white">
          {["Features", "Resources" , "Pricing", "Success Stories", "FAQ"].map(
            (item) => (
              <a
                key={item}
                href={`#${item.toLowerCase().replace(" ", "-")}`}
                className="nav-link cursor-pointer"
              >
                {item}
              </a>
            )
          )}
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div className="md:hidden">
          <button
            onClick={toggleState(setIsMobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-95 z-50">
          <div className="flex justify-end p-6">
            <button
              onClick={toggleState(setIsMobileMenuOpen)}
              className="text-white"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <nav className="flex flex-col items-center justify-center space-y-6 text-white text-2xl">
            {["Features", "Resources", "Pricing", "Success Stories", "FAQ"].map(
              (item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase().replace(" ", "-")}`}
                  className="hover:text-gray-400 cursor-pointer" 
                  onClick={toggleState(setIsMobileMenuOpen)}
                >
                  {item}
                </a>
              )
            )}
          </nav>
        </div>
      )}

    </header>
  );
};

export default Header;
