import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { countryCodes } from "../utils/CommonFunctions";
import CountryCodeSelector from "./CountryCodeSelector";

const Modal = ({ isOpen, onClose }) => {
  const initialFormData = {
    leadName: "",
    leadOrganization: "",
    leadEmail: "",
    leadPhone: "",
    leadCompanyStrength: "",
    leadLocation: "",
    leadCountryCode: {
      value: "IN",
      label: "+91 (India)",
      code: "+91",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.body.classList.remove("overflow-hidden");
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
  }, [isOpen, onClose]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    setFormErrors((prev) => ({ ...prev, [id]: "" }));
  };

  const handleCountryCodeChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, leadCountryCode: selectedOption }));
  };

  const resetRecaptcha = () => {
    try {
      recaptchaRef.current?.reset();
    } catch (error) {
      console.warn("Failed to reset reCAPTCHA:", error);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    resetRecaptcha();
    setFormErrors({});
    if (abortControllerRef.current) abortControllerRef.current.abort();
    onClose();
  };

  const validateForm = () => {
    const errors = {};
    const phoneRegex = /^[0-9]{10}$/; // Basic phone number validation for 10 digits
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

    if (!formData.leadName) {
      errors.leadName = "Full Name is required.";
    }
    if (!formData.leadOrganization) {
      errors.leadOrganization = "Organization Name is required.";
    }
    if (!formData.leadEmail) {
      errors.leadEmail = "Email Address is required.";
    } else if (!emailRegex.test(formData.leadEmail)) {
      errors.leadEmail = "Invalid email format.";
    }
    if (!formData.leadPhone) {
      errors.leadPhone = "Phone Number is required.";
    } else if (!phoneRegex.test(formData.leadPhone)) {
      errors.leadPhone = "Phone Number must be 10 digits.";
    }
    if (!formData.leadCompanyStrength) {
      errors.leadCompanyStrength = "Company Strength is required.";
    } else if (formData.leadCompanyStrength <= 0) {
      errors.leadCompanyStrength =
        "Company Strength must be a positive number.";
    }
    if (!formData.leadLocation) {
      errors.leadLocation = "Location is required.";
    }

    return errors;
  };

  const submitForm = async (data) => {
    abortControllerRef.current = new AbortController();
    const timeoutId = setTimeout(() => {
      abortControllerRef.current.abort();
    }, 15000);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/default/new-lead`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          signal: abortControllerRef.current.signal,
        }
      );

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `HTTP error: ${response.status}`);
      }

      return await response.json();
    } finally {
      clearTimeout(timeoutId);
    }
  };

  const executeRecaptcha = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      if (!token) throw new Error("No reCAPTCHA token received.");
      return token;
    } catch (error) {
      console.error("reCAPTCHA execution failed:", error);
      toast.error("reCAPTCHA verification failed. Please try again.");
      resetRecaptcha();
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate form fields
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      setLoading(false);
      return; // Stop submission if there are validation errors
    }

    try {
      const recaptchaToken = await executeRecaptcha();

      const submissionData = {
        ...formData,
        leadPhone: `${formData.leadCountryCode.code}${formData.leadPhone}`,
        leadMessage: "A new lead has been received",
        gRecaptchaResponse: recaptchaToken,
      };
      delete submissionData.leadCountryCode;

      await submitForm(submissionData);

      setShowThankYou(true);
      setTimeout(() => {
        setShowThankYou(false);
        handleClose();
      }, 3000);
    } catch (error) {
      if (error.name === "AbortError") {
        toast.error("Request timed out. Please try again.");
      } else {
        toast.error("Failed to send message. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen && !showThankYou) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75 text-black">
      {showThankYou ? (
        <div className="bg-white p-6 sm:p-8 rounded-xl shadow-2xl max-w-sm sm:max-w-md mx-auto text-center relative transition-opacity duration-300">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl opacity-10 transform -rotate-6 scale-125" />
          <div className="relative z-10">
            <div className="text-6xl text-green-500 mb-4">
              <i className="fas fa-check-circle"></i>
            </div>
            <h2 className="text-2xl font-semibold text-gray-800">
              Thank you for reaching out!
            </h2>
            <p className="text-gray-600 mt-4">
              You have been added to the waiting list. We will contact you
              shortly.
            </p>
          </div>
          <button
            className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="bg-white p-6 sm:p-10 rounded-lg shadow-2xl w-full max-w-sm sm:max-w-3xl mx-auto relative">
          <h2 className="text-2xl font-extrabold text-indigo-700 text-center mb-4 sm:mb-8">
            Welcome to TalentSwap
          </h2>
          <p className="text-gray-600 text-center mb-6 sm:mb-8">
            Let us help you get started. Please provide your details below to
            get in touch with us.
          </p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="leadName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="leadName"
                  className={`w-full p-4 rounded-lg border ${
                    formErrors.leadName ? "border-red-500" : "border-gray-300"
                  } focus:ring-2 focus:ring-indigo-500`}
                  placeholder="Enter your full name"
                  value={formData.leadName}
                  onChange={handleChange}
                />
                {formErrors.leadName && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadName}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="leadOrganization"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Organization Name
                </label>
                <input
                  type="text"
                  id="leadOrganization"
                  className={`w-full p-4 rounded-lg border ${
                    formErrors.leadOrganization
                      ? "border-red-500"
                      : "border-gray-300"
                  } focus:ring-2 focus:ring-indigo-500`}
                  placeholder="Enter your organization name"
                  value={formData.leadOrganization}
                  onChange={handleChange}
                />
                {formErrors.leadOrganization && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadOrganization}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="leadEmail"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="leadEmail"
                  className={`w-full p-4 rounded-lg border ${
                    formErrors.leadEmail ? "border-red-500" : "border-gray-300"
                  } focus:ring-2 focus:ring-indigo-500`}
                  placeholder="Enter your email address"
                  value={formData.leadEmail}
                  onChange={handleChange}
                />
                {formErrors.leadEmail && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadEmail}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="leadPhone"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number
                </label>
                <div className="flex">
                  <CountryCodeSelector
                    value={formData.leadCountryCode}
                    onChange={handleCountryCodeChange}
                    countryCodes={countryCodes}
                  />
                  <input
                    type="tel"
                    id="leadPhone"
                    className={`flex-1 p-4 rounded-r-lg border ${
                      formErrors.leadPhone
                        ? "border-red-500"
                        : "border-gray-300"
                    } focus:ring-2 focus:ring-indigo-500`}
                    placeholder="Enter your phone number"
                    value={formData.leadPhone}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          id: "leadPhone",
                          value: e.target.value.replace(/\D/g, ""),
                        },
                      })
                    }
                    inputMode="numeric"
                  />
                </div>
                {formErrors.leadPhone && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadPhone}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="leadCompanyStrength"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Company Strength
                </label>
                <input
                  type="number"
                  id="leadCompanyStrength"
                  className={`w-full p-4 rounded-lg border ${
                    formErrors.leadCompanyStrength
                      ? "border-red-500"
                      : "border-gray-300"
                  } focus:ring-2 focus:ring-indigo-500`}
                  placeholder="Enter company strength"
                  value={formData.leadCompanyStrength}
                  onChange={handleChange}
                />
                {formErrors.leadCompanyStrength && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadCompanyStrength}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="leadLocation"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Location
                </label>
                <input
                  type="text"
                  id="leadLocation"
                  className={`w-full p-4 rounded-lg border ${
                    formErrors.leadLocation
                      ? "border-red-500"
                      : "border-gray-300"
                  } focus:ring-2 focus:ring-indigo-500`}
                  placeholder="Enter your location"
                  value={formData.leadLocation}
                  onChange={handleChange}
                />
                {formErrors.leadLocation && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.leadLocation}
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className={`w-full p-4 rounded-lg bg-indigo-600 text-white font-semibold ${
                loading
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-indigo-500"
              }`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="loader"></div>
                  <span className="ml-2">Submitting...</span>
                </div>
              ) : (
                "Submit Inquiry"
              )}
            </button>
          </form>
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
        ref={recaptchaRef}
      />
    </div>
  );
};

export default Modal;
