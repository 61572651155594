import React from 'react'

const Faq = () => {
  return (
    <>
      <section
      id="faq"
      className="py-20 bg-gradient-to-br from-indigo-50 via-white to-purple-50 relative overflow-hidden"
    >
      {/* <!-- Beautiful Background Shapes with Animating Bubbles --> */}
      <div
        className="absolute inset-0 bg-gradient-to-r from-indigo-300 via-purple-300 to-indigo-300 opacity-80 z-0"
      ></div>
      {/* <!-- Floating Animated Bubbles --> */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden z-0">
        <div className="bubble bubble-1"></div>
        <div className="bubble bubble-2"></div>
        <div className="bubble bubble-3"></div>
        <div className="bubble bubble-4"></div>
        <div className="bubble bubble-5"></div>
        <div className="bubble bubble-6"></div>
        <div className="bubble bubble-7"></div>
        <div className="bubble bubble-8"></div>
        <div className="bubble bubble-9"></div>
        <div className="bubble bubble-10"></div>
      </div>
      <div className="container mx-auto px-6 text-center relative z-10">
        <h2 className="text-5xl font-extrabold text-indigo-700">
          Frequently Asked Questions
        </h2>
        <p className="text-lg mt-4 text-gray-600 max-w-3xl mx-auto">
          Have questions about TalentSwap? We’ve got you covered. Find answers
          to common questions below.
        </p>
        {/* <!-- FAQ Cards --> */}
        <div
          className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 text-left relative z-10"
        >
          {/* <!-- FAQ Item 1 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              What makes TalentSwap different from other AI hiring platforms?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              TalentSwap combines AI-powered screening, cultural fit
              assessments, and real-time analytics for actionable insights that
              make hiring smarter and faster.
            </p>
          </div>
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              How do TalentSwap integrations work?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              TalentSwap integrates seamlessly with your ATS, CRM, and HR
              software to streamline recruitment workflows.
            </p>
          </div>
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              Can I customize my TalentSwap experience?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Yes, with the Enterprise Plan, you can customize features, add
              white-label branding, and integrate custom workflows.
            </p>
          </div>
          {/* <!-- FAQ Item 4 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              How secure is my data on TalentSwap?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              We prioritize security with industry-standard encryption and
              access controls, ensuring your data is protected.
            </p>
          </div>
          {/* <!-- FAQ Item 5 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              Can TalentSwap integrate with my existing HR software?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Yes, especially with our Enterprise Plan, which allows custom
              integrations to streamline your workflows.
            </p>
          </div>
          {/* <!-- FAQ Item 6 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              How accurate is TalentSwap's AI in screening candidates?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Our AI models are highly accurate and designed to quickly identify
              candidates who best fit your role.
            </p>
          </div>
          {/* <!-- FAQ Item 7 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              What support does TalentSwap provide?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Support varies by plan. Free and Basic Plans include email
              support, while Premium and Enterprise Plans offer phone or 24/7
              priority support.
            </p>
          </div>
          {/* <!-- FAQ Item 8 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              Can I customize TalentSwap to my company’s needs?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Yes, with the Enterprise Plan, you can fully customize the
              platform, including white-label branding and custom workflows.
            </p>
          </div>
          {/* <!-- FAQ Item 9 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              Can I try TalentSwap before committing to a paid plan?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              Yes, our Free Plan allows you to experience TalentSwap with up to
              3 job postings and 50 AI screenings per month.
            </p>
          </div>
          {/* <!-- FAQ Item 10 --> */}
          <div
            className="faq-item bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:scale-105 relative"
          >
            <h3 className="text-xl font-semibold text-indigo-600">
              What are the available payment plans?
            </h3>
            <p className="mt-4 text-gray-700 text-center">
              We offer flexible pricing, including a Free Plan, Pay-as-You-Go,
              and various paid plans. The Enterprise Plan provides custom
              pricing and advanced features.
            </p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Faq
