import React from 'react'
import { Link } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#4885ed1a]">
    <div className="container mx-auto px-4">
      <div className="flex justify-center h-full items-center">
        <div className="w-full md:w-7/12 text-center">
          <div className="form-input-content text-center error-page">
            <h1 className="text-9xl md:text-[9.375rem] font-bold mb-2">
              404
            </h1>
            <h4 className="text-xl md:text-2xl font-medium mb-2">
              <FaExclamationTriangle className="text-yellow-400 inline-block mr-2 mb-1" />
              The page you were looking for is not found!
            </h4>
            <p className="text-base md:text-lg text-gray-500 mb-4">
              You may have mistyped the address or the page may have moved.
            </p>
            <div className="mt-4">
              <Link
                className="inline-block px-6 py-3 text-white  rounded-lg bg-blue-500 hover:bg-blue-600"
                to={"/"}
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NotFound
