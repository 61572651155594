import React, { useState } from "react";
import Modal from "./Modal";

const Header2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <section className="relative overflow-hidden bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-28">
      {/* Floating Geometric Shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="shape shape-1 rotate-animation"></div>
        <div className="shape shape-2 pulse-animation"></div>
        <div className="shape shape-3 rotate-reverse-animation"></div>
        <div className="shape shape-4 pulse-animation"></div>
        <div className="shape shape-5 rotate-animation"></div>
        <div className="particle particle-1 fade-in-animation"></div>
        <div className="particle particle-2 pulse-animation"></div>
        <div className="particle particle-3 fade-in-animation"></div>
        <div className="particle particle-4 pulse-animation"></div>
        <div className="particle particle-5 fade-in-animation"></div>
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-6 text-center z-10">
        <h1 className="hero-text text-6xl font-extrabold leading-tight relative">
          <span className="block overflow-hidden">
            <span className="text-reveal block">Smarter Hiring Starts Here</span>
          </span>
        </h1>
        <p className="mt-6 text-xl font-light relative">
          <span className="block overflow-hidden">
            <span className="text-reveal block">
              AI-powered insights. Better candidates. Faster decisions.
            </span>
          </span>
        </p>

        {/* CTA Button */}
        <a
          id="onboarding-btn"
          className="cta-btn inline-block mt-8 bg-white text-indigo-600 px-8 py-4 rounded-full text-xl font-semibold hover:bg-gray-100 transition-all duration-300 ease-in-out cursor-pointer"
          onClick={toggleModal}
        >
          Try TalentSwap
        </a>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </section>
  );
};

export default Header2;
