import React, { useEffect } from 'react';
import image1 from '../assets/scrollSection/01.webp';
import image2 from '../assets/scrollSection/02.webp';
import image3 from '../assets/scrollSection/03.webp';
import image4 from '../assets/scrollSection/04.webp';
import image5 from '../assets/scrollSection/05.webp';

const ScrollSection = () => {

  useEffect(() => {
    const images = document.querySelectorAll(".scroll-image");
    const scrollSection = document.getElementById("scroll-section");
    const totalImages = images.length;

    const updateImageOnScroll = () => {
      const sectionHeight = scrollSection.offsetHeight;
      const scrollY = window.scrollY;
      const sectionTop = scrollSection.offsetTop;
      const sectionBottom = sectionTop + sectionHeight;
      const windowHeight = window.innerHeight;

      let imageIndex = 0;

      if (scrollY < sectionTop) {
        imageIndex = 0;
      } else if (scrollY > sectionBottom - windowHeight) {
        imageIndex = totalImages - 1;
      } else {
        const scrollPosition = scrollY - sectionTop;
        const scrollFraction = scrollPosition / (sectionHeight - windowHeight);
        imageIndex = Math.min(
          totalImages - 1,
          Math.floor(scrollFraction * totalImages)
        );
      }

      images.forEach((img, idx) =>
        img.classList.toggle("active", idx === imageIndex)
      );
    };

    window.addEventListener("scroll", updateImageOnScroll);
    return () => window.removeEventListener("scroll", updateImageOnScroll);
  }, []);

  return (
    <section id="scroll-section" className="relative h-[500vh] bg-white">
      <div
        id="scroll-wrapper"
        className="sticky top-0 h-screen flex items-center justify-center"
      >
        <div id="scroll-images" className="relative w-full h-full flex items-center justify-center">
          <img src={image1} alt="Image 1" className="scroll-image active" />
          <img src={image2} alt="Image 2" className="scroll-image" />
          <img src={image3} alt="Image 3" className="scroll-image" />
          <img src={image4} alt="Image 4" className="scroll-image" />
          <img src={image5} alt="Image 5" className="scroll-image" />
        </div>
      </div>
    </section>
  );
};

export default ScrollSection;
