import React from 'react'
import client1 from '../assets/clients/1.webp';
import client2 from '../assets/clients/2.webp';

const SuccessStories = () => {
  return (
    <>
      <section
      id="success-stories"
      className="py-20 "
    >
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-extrabold text-indigo-700">
          Success Stories & What Our Clients Say
        </h2>
        <p className="text-lg mt-4 text-gray-600 max-w-3xl mx-auto">
          Learn how TalentSwap has helped companies revolutionize their hiring
          process and hear directly from our satisfied clients.
        </p>
        {/* <!-- Success Stories Section --> */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* <!-- Success Story 1 --> */}
          <div
            className="story-item bg-gradient-to-r from-indigo-100 to-purple-100 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105"
          >
            <h3 className="text-xl font-semibold text-indigo-700">
              HubSpot: Cutting Hiring Time by 50%
            </h3>
            <p className="mt-4 text-gray-700">
              HubSpot needed to hire quickly without compromising on quality. By
              using TalentSwap’s AI-driven screening, they reduced hiring time
              by 50% while increasing the quality of candidates by 40%.
            </p>
            <p className="mt-4 text-gray-700">
              <strong
                >“TalentSwap has been a game-changer for us. We're now
                interviewing only the top candidates, saving weeks in our hiring
                process.”</strong
              >
              — David Torres, Head of Recruiting, HubSpot
            </p>
          </div>
          {/* <!-- Success Story 2 --> */}
          <div
            className="story-item bg-gradient-to-r from-indigo-100 to-purple-100 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105"
          >
            <h3 className="text-xl font-semibold text-indigo-700">
              Slack: Boosting Cultural Fit by 35%
            </h3>
            <p className="mt-4 text-gray-700">
              Slack was expanding quickly and needed to ensure new hires aligned
              with their company culture. Using TalentSwap’s cultural fit
              assessments, they improved cultural alignment by 35%, enhancing
              retention and team synergy.
            </p>
            <p className="mt-4 text-gray-700">
              <strong
                >“The insights we get from TalentSwap are invaluable. It's
                helped us find not just skilled candidates but those who truly
                fit our company culture.”</strong
              >
              — Maria Gomez, Talent Acquisition Manager, Slack
            </p>
          </div>
        </div>
        {/* <!-- What Our Clients Say (Testimonials) Section --> */}
        <div className="mt-12">
          <h3 className="text-4xl font-extrabold text-indigo-700">
            What Our Clients Say
          </h3>
          <div
            className="testimonial-carousel grid grid-cols-1 md:grid-cols-2 gap-8 mt-8"
          >
            {/* <!-- Testimonial 1 --> */}
            <div
              className="testimonial-card bg-white bg-opacity-80 backdrop-blur-lg p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-6">
                <img
                  src={client1}
                  alt="Client 1"
                  className="w-16 h-16 rounded-full"
                />
                <div className="ml-4 text-left">
                  <h4 className="text-xl font-semibold text-indigo-600">
                  David Carter
                  </h4>
                  <p className="text-sm text-gray-600">
                  Head of Talent Acquisition, HubSpot
                  </p>
                </div>
              </div>
              <blockquote className="text-gray-700 italic">
                <i className="fas fa-quote-left text-indigo-600"></i> TalentSwap has completely changed the way we hire. The AI-powered screening tool identifies top candidates with incredible precision. We've slashed hiring time by 50%, and candidate quality has improved significantly. TalentSwap has become an essential part of our recruiting toolkit.
              </blockquote>
              <div className="mt-4 flex items-center justify-center">
                <span className="text-yellow-500">★★★★★</span>
              </div>
            </div>
            {/* <!-- Testimonial 2 --> */}
            <div
              className="testimonial-card bg-white bg-opacity-80 backdrop-blur-lg p-8 rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-6">
                <img
                  src={client2}
                  alt="Client 2"
                  className="w-16 h-16 rounded-full"
                />
                <div className="ml-4 text-left">
                  <h4 className="text-xl font-semibold text-indigo-600">
                  Sophia Mitchell
                  </h4>
                  <p className="text-sm text-gray-600">Senior HR Manager, Transmit Security</p>
                </div>
              </div>
              <blockquote className="text-gray-700 italic">
                <i className="fas fa-quote-left text-indigo-600"></i> Finding candidates with the right technical skills and cultural fit has always been a challenge for us. With TalentSwap's cultural alignment tools, we boosted employee retention and improved team collaboration. It’s no longer just about skills—it's about building the right team dynamics.
              </blockquote>
              <div className="mt-4 flex items-center justify-center">
                <span className="text-yellow-500">★★★★★</span>
              </div>
            </div>
          </div>
          {/* <!-- Carousel Controls --> */}
          {/* <div className="flex justify-center mt-8 space-x-4">
            <button
              className="carousel-prev w-10 h-10 rounded-full bg-indigo-600 text-white flex items-center justify-center"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <button
              className="carousel-next w-10 h-10 rounded-full bg-indigo-600 text-white flex items-center justify-center"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div> */}
        </div>
      </div>
    </section>
    </>
  )
}

export default SuccessStories
