import React, { useState } from "react";
import Modal from "./Modal";

const Pricing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  return (
    <section
      id="pricing"
      className="py-24 bg-gradient-to-br from-gray-50 via-indigo-100 to-purple-100 relative overflow-hidden"
    >
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-6xl font-extrabold text-indigo-700 leading-tight mb-8">
          Flexible Pricing Plans
        </h2>
        <p className="text-lg mt-4 text-gray-700 max-w-2xl mx-auto">
          Get started with TalentSwap for free, or upgrade to TalentSwap+ for
          even more advanced features and flexibility.
        </p>
        {/* <!-- Pricing Cards --> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-16 relative z-10">
          {/* <!-- TalentSwap Plan --> */}
          <div className="pricing-card relative bg-white bg-opacity-90 backdrop-blur-lg p-10 rounded-3xl shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 flex flex-col justify-between h-full">
            <div className="absolute top-4 right-4 bg-indigo-600 text-white text-sm px-4 py-1 rounded-full shadow-md">
              Free Plan
            </div>
            <div>
              <h3 className="text-3xl font-bold text-indigo-600 text-center mb-4">
                TalentSwap
              </h3>
              <p className="text-5xl font-extrabold text-center text-gray-800">
                $0<span className="text-xl text-gray-500">/month</span>
              </p>
              <p className="text-lg mt-2 text-center text-gray-600">
                Ideal for small teams looking to start hiring smarter.
              </p>
              <ul className="mt-8 space-y-6 text-center text-gray-700">
                <li className="flex items-center justify-center">
                  <i className="fas fa-briefcase text-green-500 mr-3"></i>
                  <span>
                    Up to{" "}
                    <span className="text-indigo-600 font-bold text-xl">5</span>{" "}
                    Job Postings per month
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-brain text-green-500 mr-3"></i>
                  <span>
                    <span className="text-indigo-600 font-bold text-xl">
                      50
                    </span>{" "}
                    AI Candidate Analyses included
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-user-check text-green-500 mr-3"></i>
                  <span>
                    <span className="text-indigo-600 font-bold text-xl">5</span>{" "}
                    Personality Tests included
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-chart-line text-green-500 mr-3"></i>
                  <span>
                    Basic{" "}
                    <span className="text-indigo-600 font-bold text-xl">
                      Analytics Dashboard
                    </span>
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-envelope text-green-500 mr-3"></i>
                  <span>
                    <span className="text-indigo-600 font-bold text-xl">
                      Email
                    </span>{" "}
                    Support
                  </span>
                </li>
              </ul>
            </div>
            <div className="mt-8">
              <button
                onClick={toggleModal}
                className="w-full bg-indigo-600 text-white text-lg px-6 py-4 rounded-full hover:bg-indigo-700 transition-all duration-300"
              >
                Start Free
              </button>
            </div>
          </div>
          {/* <!-- TalentSwap+ Plan --> */}
          <div className="pricing-card relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white p-10 rounded-3xl shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 flex flex-col justify-between h-full">
            <span className="absolute top-4 right-4 bg-yellow-400 text-xs text-black px-3 py-1 rounded-full shadow-md">
              Best Value
            </span>
            <div>
              <h3 className="text-3xl font-bold text-center mb-4">
                TalentSwap+
              </h3>
              <p className="text-5xl font-extrabold text-center text-white">
                Pay-as-You-Go
              </p>
              <p className="text-lg mt-2 text-center text-indigo-200">
                Unlock unlimited potential with advanced features.
              </p>
              <ul className="mt-8 space-y-6 text-center text-indigo-100">
                <li className="flex items-center justify-center">
                  <i className="fas fa-briefcase text-yellow-300 mr-3"></i>
                  <span>
                    <span className="text-yellow-300 font-bold text-xl">
                      Unlimited
                    </span>{" "}
                    Job Postings
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-dollar-sign text-yellow-300 mr-3"></i>
                  <span>
                    Only{" "}
                    <span className="text-yellow-300 font-bold text-xl">
                      $1
                    </span>{" "}
                    per AI Candidate Analysis
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-user-check text-yellow-300 mr-3"></i>
                  <span>
                    <span className="text-yellow-300 font-bold text-xl">
                      $5
                    </span>{" "}
                    per Personality Test
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-chart-line text-yellow-300 mr-3"></i>
                  <span>
                    Advanced{" "}
                    <span className="text-yellow-300 font-bold text-xl">
                      Analytics & Reporting
                    </span>
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-share-alt text-yellow-300 mr-3"></i>
                  <span>
                    Shareable{" "}
                    <span className="text-yellow-300 font-bold text-xl">
                      Candidate Insights
                    </span>
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-globe text-yellow-300 mr-3"></i>
                  <span>
                    Branded{" "}
                    <span className="text-yellow-300 font-bold text-xl">
                      Careers Page
                    </span>
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-paint-brush text-yellow-300 mr-3"></i>
                  <span>
                    <span className="text-yellow-300 font-bold text-xl">
                      White-labeling
                    </span>
                  </span>
                </li>
                <li className="flex items-center justify-center">
                  <i className="fas fa-headset text-yellow-300 mr-3"></i>
                  <span>
                    Dedicated{" "}
                    <span className="text-yellow-300 font-bold text-xl">
                      24x7 Support
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="mt-8">
              <p className="text-indigo-200 text-center mb-4">
                Use TalentSwap+ freely and pay only for what you use at the end
                of the month.
              </p>
              <button
                onClick={toggleModal}
                className="w-full bg-white text-indigo-600 text-lg px-6 py-4 rounded-full hover:bg-gray-100 transition-all duration-300"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
        {/* <!-- Decorative Elements --> */}
        <div className="absolute top-0 left-0 w-80 h-80 bg-gradient-to-r from-blue-400 via-purple-500 to-pink-400 rounded-full blur-3xl opacity-20 -z-10 transform -translate-x-24 -translate-y-24"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-r from-yellow-400 via-pink-500 to-red-500 rounded-full blur-3xl opacity-20 -z-10 transform translate-x-20 translate-y-16"></div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </section>
  );
};

export default Pricing;
