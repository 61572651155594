import React, { useState } from "react";

const Resorces = () => {
  const [hires, setHires] = useState("");
  const [traditionalTime, setTraditionalTime] = useState(30);
  const [traditionalCost, setTraditionalCost] = useState(100);
  const [timeSaved, setTimeSaved] = useState(null);
  const [costSaved, setCostSaved] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const hiresCount = parseInt(hires);
    const timePerHire = parseInt(traditionalTime);
    const costPerHire = parseInt(traditionalCost);

    if (
      isNaN(hiresCount) ||
      isNaN(timePerHire) ||
      isNaN(costPerHire) ||
      hiresCount <= 0 ||
      timePerHire <= 0 ||
      costPerHire <= 0 ||
      hiresCount > 1000 ||
      timePerHire > 365 ||
      costPerHire > 100000
    ) {
      alert("Please enter realistic values.");
      return;
    }

    const talentSwapTimePerHire = 3;
    const talentSwapCostPerHire = 1;

    const timeDifference = Math.max(0, timePerHire - talentSwapTimePerHire);
    const costDifference = Math.max(0, costPerHire - talentSwapCostPerHire);

    const calculatedTimeSaved = (hiresCount * timeDifference) / hiresCount;
    const calculatedCostSaved = hiresCount * costDifference;

    setTimeSaved(calculatedTimeSaved);
    setCostSaved(calculatedCostSaved);
  };

  return (
    <section
      id="resources"
      className="py-20 bg-gradient-to-br from-gray-50 via-indigo-100 to-purple-100 relative"
    >
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-extrabold text-indigo-700">
          Resources & Getting Started
        </h2>
        <p className="text-lg mt-4 text-gray-600 max-w-3xl mx-auto">
          Explore our resources, calculate your ROI, and see how easy it is to
          start with TalentSwap.
        </p>

        {/* ROI Calculator */}
        <div className="mt-12 p-8 bg-white rounded-xl shadow-lg">
          <h3 className="text-xl font-semibold text-indigo-700">
            Calculate Your ROI
          </h3>
          <p className="mt-4 text-gray-700">
            See how much time and money you could save by switching to
            TalentSwap.
          </p>

          {/* ROI Calculator Form */}
          <form
            onSubmit={handleSubmit}
            className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6"
          >
            {/* Input: Number of hires per month */}
            <div>
              <label
                htmlFor="hires"
                className="block text-left font-semibold text-gray-600"
              >
                Hires per month
              </label>
              <input
                type="number"
                id="hires"
                value={hires}
                onChange={(e) => setHires(e.target.value)}
                className="mt-2 p-3 rounded-lg w-full bg-gray-100 border focus:ring-2 focus:ring-indigo-400"
                placeholder="Enter number of hires"
                min="1"
                max="1000"
                required
              />
            </div>

            <div>
              <label
                htmlFor="traditional-time"
                className="block text-left font-semibold text-gray-600"
              >
                Traditional hiring time (Per candidate in days)
              </label>
              <input
                type="number"
                id="traditional-time"
                value={traditionalTime}
                onChange={(e) => setTraditionalTime(e.target.value)}
                className="mt-2 p-3 rounded-lg w-full bg-gray-100 border focus:ring-2 focus:ring-indigo-400"
                min="1"
                max="365"
                required
              />
            </div>

            {/* Input: Cost per hire (Traditional) with dollar symbol */}
            <div>
              <label
                htmlFor="traditional-cost"
                className="block text-left font-semibold text-gray-600"
              >
                Traditional cost per hire
              </label>
              <div className="relative">
                <span className="mt-2 absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  id="traditional-cost"
                  value={traditionalCost}
                  onChange={(e) => setTraditionalCost(e.target.value)}
                  className="mt-2 p-3 pl-8 rounded-lg w-full bg-gray-100 border focus:ring-2 focus:ring-indigo-400"
                  min="1"
                  max="100000"
                  required
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-span-1 md:col-span-3 mt-4">
              <button
                type="submit"
                className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition duration-300 w-full"
              >
                Calculate Savings
              </button>
            </div>
          </form>

          {/* ROI Results */}
          {timeSaved !== null && costSaved !== null && (
            <div id="roi-results-container" className="mt-8" aria-live="polite">
              <h4 className="text-xl font-semibold text-indigo-600">
                Your Results:
              </h4>
              <p className="mt-4 text-gray-700">
                You can save approximately{" "}
                <span className="text-lg font-bold text-indigo-600">
                ${costSaved.toLocaleString()}
                </span> per
                month.
              </p>
              <p className="mt-4 text-gray-700">
                You can reduce your hiring time by{" "} 
                <span className="text-lg font-bold text-indigo-600">
                {timeSaved}
                </span> days per
                candidate.
              </p>
            </div>
          )}
        </div>

        {/* Getting Started Steps */}
        <div className="mt-12 text-left grid grid-cols-1 md:grid-cols-3 gap-8">
          {["Sign Up", "Create Job Postings", "Start Screening"].map(
            (step, index) => (
              <div
                key={index}
                className="step-item bg-indigo-600 text-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105"
              >
                <div className="step-number bg-white text-indigo-600 w-12 h-12 flex items-center justify-center font-semibold rounded-full text-2xl mx-auto">
                  {index + 1}
                </div>
                <h3 className="mt-6 text-xl font-semibold text-center">
                  {step}
                </h3>
                <p className="mt-2 text-center">
                  {index === 0 &&
                    "Create a free account or choose the plan that suits your needs."}
                  {index === 1 &&
                    "Customize assessments and post jobs to attract candidates."}
                  {index === 2 &&
                    "Use AI tools to screen candidates in real-time."}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Resorces;
