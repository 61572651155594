import React, { useEffect } from 'react';
import Header from '../components/Header';
import Header2 from '../components/Header-2';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import ScrollSection from '../components/ScrollSection';
import Feature from '../components/Feature';
import FeatureComparison from '../components/FeatureComparison';
import Resorces from '../components/Resorces';
import TrustedCompanies from '../components/TrustedCompanies';
import SuccessStories from '../components/SuccessStories';
import Faq from '../components/Faq';

const Home = () => {

  return (
    <div>
      <Header />
      <Header2 />
      <ScrollSection />
      <Feature />
      <FeatureComparison />
      <Resorces />
      <TrustedCompanies />
      <Pricing />
      <SuccessStories />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
