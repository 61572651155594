import React, { useState, useRef, useEffect } from 'react';
import Flag from 'react-world-flags';

const CountryCodeSelector = ({ value, onChange, countryCodes }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setSearch('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        className="w-24 p-4 rounded-l-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 flex items-center justify-between"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className="flex items-center">
          <Flag code={value.value} className="w-6 h-4 mr-2" />
          <span>{value.code}</span>
        </div>
      </button>

      {showDropdown && (
        <div className="absolute z-50 mt-1 w-64 bg-white border border-gray-300 rounded-lg shadow-lg">
          <div className="p-2 border-b border-gray-300">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-indigo-500"
              placeholder="Search countries..."
            />
          </div>
          <div className="max-h-48 overflow-y-auto">
            {countryCodes
              .filter((country) =>
                country.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((country) => (
                <button
                  key={country.iso}
                  type="button"
                  className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                  onClick={() => {
                    onChange({
                      value: country.iso,
                      label: `${country.code} (${country.name})`,
                      code: country.code,
                    });
                    setShowDropdown(false);
                    setSearch('');
                  }}
                >
                  <Flag code={country.iso} className="w-6 h-4 mr-2" />
                  <span>
                    {country.code} ({country.name})
                  </span>
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryCodeSelector;